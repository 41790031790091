import React, { useContext, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button } from "@bain/design-system"
import { toast } from "react-toastify";
import { Context } from "../Context";

const Sections = (props) => {
  let [selected, setSelected] = useState([]);
  const { sectionsSelected, setSectionsSelected } = useContext(Context); 
   
  try {
    const handleButtonClick = () => {
      props.actionProvider.FinalizeSections(selected);
      setSectionsSelected(true);
      const multiselect = document.querySelector('.dropdown-heading-dropdown-arrow');
      if(multiselect !== undefined && multiselect!== null)
      {
        multiselect.style.cursor = "no-drop";
      }           
    };
    let options = [];
    if (props.Sections !== undefined && props.Sections !== null) {
      options = props.Sections.map((value, index) => {
        return { label: value, value: value };
      });
    }

    return (
      <div>
        <div className="rowSection">Please select as many as you need and hit ‘Complete selection’ to continue:</div>
        <div className="sectionContainer">
          <div className="colMultiSelect">
            <MultiSelect  disabled={sectionsSelected}
              options={options}
              value={selected}
              onChange={setSelected}
              isCreatable={false}
              hasSelectAll={true}
            />
          </div>
          <div className="colButton">
            <Button className="sectionbutton" disabled={sectionsSelected}
              onClick={() => handleButtonClick()}>
              Complete selection
            </Button>
          </div>
        </div>
      </div>
    );
  }
  catch (error) {
    toast.error("Error: loading sections- " + error.message);
  }
};

export default Sections;
