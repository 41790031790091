import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const AcquireAPIToken = async () => {
    if (msalInstance.getActiveAccount()) {
        var tokenRequest = {
            scopes: ["api://"+ process.env.REACT_APP_CLIENTID +"/access_as_user"],
        };
        return await msalInstance.acquireTokenSilent(tokenRequest)
            .then(response => {
                // get access token from response
                // console.log(response.accessToken);
                return response.accessToken
            })
            .catch(err => {
                // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                if (err.name === "InteractionRequiredAuthError") {
                    return msalInstance.acquireTokenPopup(tokenRequest)
                        .then(response => {
                            // get access token from response
                            // response.accessToken
                        })
                        .catch(err => {
                            // handle error
                        });
                }
            });
    } else {
        // user is not logged in, you will need to log them in to acquire a token
    }
}

export default AcquireAPIToken;



