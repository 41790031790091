import React, { useContext, useEffect } from "react";
import { Context } from "../Context";

export default function ObjectiveMessage() {

  const { setSectionsSelected } = useContext(Context); 

  useEffect(() => {
    //Disabling the predefined section drodown
    setSectionsSelected(true);
    const multiselect = document.querySelector('.dropdown-heading-dropdown-arrow');
    if(multiselect !== undefined && multiselect!== null)
    {
      multiselect.style.cursor = "no-drop";
    }
    
  },[]);

  return (
    <section>
      Are there any additional objectives / key outcomes for this interview not covered by those sections?
      <br></br>
      <br></br>
      <strong>Note:</strong> A more descriptive statement will yield better outcomes (e.g., to understand how upcoming regulatory changes will affect data handling policies)
    </section>
  );
}