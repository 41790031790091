import React, { useContext, useEffect } from 'react';
import { Context } from "../Context";
import config from "../chatbot/Config";

const Objectives = (props) => {

  const { userId } = useContext(Context);

  useEffect(() => {
    let paramState = props.ParamState;
    if (userId === undefined || userId === null) {
      paramState.user_id = Date.now();
    }
    else {
      paramState.user_id = userId;
    }
    config.ParamState = paramState;
    config.PreviewState = props.PreviewState;
  });
  return (
    <>
    </>
  );
};
export default Objectives;
