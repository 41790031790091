import React, { useContext, useEffect } from 'react';
import { Context } from "../Context";
import config from "../chatbot/Config";
import { Button } from "@bain/design-system"
import { appInsights } from '../appinsights/appInsights';

const Revision = (props) => {

  const { userId } = useContext(Context);  
  const { callGuideCteated, setCallGuideCteated } = useContext(Context);  

  useEffect(() => {
    let paramState = props.ParamState;
    if (userId === undefined || userId === null) {
      paramState.user_id = Date.now();
    }
    else {
      paramState.user_id = userId;
    }
    config.ParamState = paramState;
    config.PreviewState = props.PreviewState;
  });
  const handleButtonClick = async () => {
    props.actionProvider.CreateCallGuide(config.ParamState);
    setCallGuideCteated(true);
    appInsights.trackEvent("Creating call guide", {parameters: config.ParamState, callguide: config.PreviewState});
  };
  return (
    <div>
      <div className="sectionContainer">
        <div className="colMultiSelect">
          &nbsp;
        </div>
        <div className="colButton">
          <Button className="sectionbutton" disabled={callGuideCteated}
            onClick={() => handleButtonClick()}>
            Generate call guide
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Revision;
