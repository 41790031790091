const JSONParser = ({ jsonData }) => {
    const data = jsonData;
    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text);
    }
    return (
      <div className="callguidePreview">
        {data.title? 
        <>
          <strong title="click to copy" className="pointer" onClick={() => handleCopyClick(data.title)}>Title:&nbsp;{data.title}:</strong><br></br><br></br>
        </>
        : ""}
        {data.objectives? 
        <>
          <strong>Objectives:</strong>
          {data.objectives.map((item, index) => (
            <div className="section" key={index}>
              <ul>
                  <li title="click to copy" className="pointer" onClick={() => handleCopyClick(item)} key={"obj"+index}>{item}</li>
              </ul>
            </div>
          ))}
          <br></br>
        </>
        : ""}
        {data.introduction? 
        <>
          <strong>Introduction:</strong>&nbsp;{data.introduction}<br></br><br></br>
        </>
        : ""}
        {data.sections? 
        <>
          {data.sections.map((section, index) => (
            <div key={index}>
              <strong title="click to copy" className="pointer" onClick={() => handleCopyClick(section.title)}>{section.title}:</strong>
              <ol>
                {section.questions.map((question, qIndex) => (
                  <li title="click to copy" className="pointer" onClick={() => handleCopyClick(question)} key={qIndex}>{question}</li>
                ))}
              </ol>
            </div>
          ))}
        </>
        : ""}
      </div>
    )
}

export default JSONParser;