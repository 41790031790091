import { createChatBotMessage } from "react-chatbot-kit";
import Objectives from "../widgets/Objectives";
import Revision from "../widgets/Revision";
import BotAvatar from "../components/BotAvatar";
import UserAvatar from "../components/UserAvatar";
import Final from "../widgets/Final";
import PreviewDownload from "../components/PreviewDownload";
import InitialMessage from "../components/InitialMessage"
import Sections from "../widgets/Sections";

const botName = process.env.REACT_APP_BOTNAME;

var initialMessagesTemplate = <InitialMessage botName={botName}></InitialMessage>;
const initialMessages = createChatBotMessage(
  initialMessagesTemplate,
  {
    withAvatar: true,
    content: "Target",
  }
)

// const messageHistory = JSON.parse(localStorage.getItem("messageHistory")) || [];

// if(messageHistory.length < 1)
//   {
//     messageHistory.push(initialMessages); 
//   }

// store the messages array in local storage
//localStorage.setItem('messageHistory', JSON.stringify(messageHistory));

//congig for chatbot
const config = {
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#F2BFBF",
    },
    chatButton: {
      backgroundColor: "#D6D6D6",
    }
  },
  //initialMessages: [createChatBotMessage(`Hi there, I am ${botName}!`)],
  initialMessages: [initialMessages],
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
    previewDownload: (props) => <PreviewDownload {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
  },
  state: {
    PreviewState: "",
    Loading: false,
    ParamState: {
      user_id: "",
      revision_prompt: "",
      industry: "",
      target: "",
      sections: "",
      userobjectives: "",
      objectives: "",
      additionalInputs: "",
      temp: parseFloat(process.env.REACT_APP_TEMP),
      max_p: parseFloat(process.env.REACT_APP_MAX_P),
      max_tokens: parseInt(process.env.REACT_APP_MAX_TOKENS),
      frequency_penalty: parseInt(process.env.REACT_APP_FREQUENCY_PENALTY),
      presence_penalty: parseInt(process.env.REACT_APP_PRESENCE_PENALTY),
      chunking_strategy: process.env.REACT_APP_CHUNKING_STRATEGY,
      k: parseInt(process.env.REACT_APP_K),
      nquestions: parseInt(process.env.REACT_APP_NQUESTIONS)
    },
    Sections: ""
  },
  //widgets declaration section which we use in our bot for display information
  widgets: [
    {
      widgetName: "Revision",
      widgetFunc: (props) => <Revision {...props} />,
      mapStateToProps: ["PreviewState", "ParamState", "Loading", "User", "UserId"],
    },
    {
      widgetName: "Objectives",
      widgetFunc: (props) => <Objectives {...props} />,
      mapStateToProps: ["PreviewState", "ParamState", "Loading", "User", "UserId"],
    },
    {
      widgetName: "Final",
      widgetFunc: (props) => <Final {...props} />,
      mapStateToProps: ["PreviewState", "ParamState", "Loading", "User", "UserId"],
    },
    {
      widgetName: "Sections",
      widgetFunc: (props) => <Sections {...props} />,
      mapStateToProps: ["PreviewState", "ParamState", "Loading", "User", "UserId", "Sections", "messages"],
    }

  ],
};

export default config;
