class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse = (message) => {
    //convert message in lowercase wich passed by user
    const lowerCase = message.toLowerCase().trim();

    //define your keyword in condition and return handler function whixh created in actionprovider

    // if (
    //   lowerCase.includes("hi") ||
    //   lowerCase.includes("hii") ||
    // ) {
    //   return this.actionProvider.Tools();
    // }

    // add a new message
    const newPrompt = [
      {
        "role": "user",
        "content": lowerCase,
      },
    ]

    var messageHistory = JSON.parse(localStorage.getItem("messageHistory")) || [];

    messageHistory = [...messageHistory, ...newPrompt];

     // store the messages array in local storage
    localStorage.setItem('messageHistory', JSON.stringify(messageHistory));
    // console.log(this.state);
    return this.actionProvider.handleUserMessage(lowerCase);
  };
}

export default MessageParser;
