import React, { useContext } from "react";
import { Context } from "../Context";
import UserIcon from '../assets/user-icon.jpg'

const UserAvatar = (props) => {
  const { profileImage } = useContext(Context);
  const { userName } = useContext(Context);

  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        {<img className='UserIcon' alt={userName} src={profileImage ? profileImage : UserIcon}></img>}
      </div>
    </div>
  );
};

export default UserAvatar;