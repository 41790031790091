import AcquireAPIToken from "../auth/AcquireAPIToken";

const callGuideAPIs = async (props) => {
  let requestOptions;
  let url;

  const fetchApi = async (param) => {
    try {

      let acceesToken = await AcquireAPIToken();

      requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + acceesToken },
        body: JSON.stringify({
          "user_id": param.user_id,
          "revision_prompt": param.revision_prompt,
          "industry": param.industry,
          "target": param.target,
          "sections": param.sections,
          "objectives": param.objectives,
          "temp": param.temp,
          "max_p": param.max_p,
          "max_tokens": param.max_tokens,
          "frequency_penalty": param.frequency_penalty,
          "presence_penalty": param.presence_penalty,
          "chunking_strategy": param.chunking_strategy,
          "k": param.k,
          "nquestions": param.nquestions
        }),
      };
      url = process.env.REACT_APP_API_GET_CALL_GUIDE;
      return await fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };
  return fetchApi(props);
}
export default callGuideAPIs;