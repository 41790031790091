import React, { useContext, useEffect } from 'react';
import { Context } from "../Context";
// import ProcessingImage from '../assets/processing.gif'

const Final = (props) => {

  const { setCallGuide } = useContext(Context);
  const { setLoading } = useContext(Context);
  // const { setUser } = useContext(Context);
  // const { setUserId } = useContext(Context);

  useEffect(() => {
    setCallGuide((callGuide) => props.PreviewState);
    setLoading((loading) => props.Loading);
    // setUser((user) => props.User);
    // setUserId((userId) => props.UserId);
  });

  return (
    <>
      {/* {loading? <div><center><img src={ProcessingImage} alt="Processing..."/></center></div>: ""} */}
    </>
  );
};
export default Final;
