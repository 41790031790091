import { useEffect, useContext } from 'react';
import Chatbot from "react-chatbot-kit";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import 'react-chatbot-kit/build/main.css';
import Config from "./chatbot/Config";
import MessageParser from "./chatbot/MessageParser";
import ActionProvider from "./chatbot/ActionProvider";
import PreviewDownload from "./components/PreviewDownload";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authConfig';
import "./App.css";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { Context } from "./Context";
import { Button } from "@bain/design-system"
import { appInsights } from '../src/appinsights/appInsights';

const botName = process.env.REACT_APP_BOTNAME;
const appName = botName.charAt(0).toUpperCase() + botName.slice(1)


// const dotenv = require("dotenv");
// dotenv.config({ path: ".env" }); 

const msalInstance = new PublicClientApplication(msalConfig);
const MainContent = () => {
  const accounts = msalInstance.getAllAccounts();
  const { setProfileImage } = useContext(Context);
  const { setUserName } = useContext(Context);
  const { setUser } = useContext(Context);
  const { userId, setUserId } = useContext(Context);
  // eslint-disable-next-line
  useEffect(() => {
    // Use the same publicClientApplication instance provided to MsalProvider
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
    // console.log(accounts);
    // console.log(msalInstance.getActiveAccount());

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        setUserName((userName) => account.userName);
        setUser((user) => account.name);
        setUserId((userId) => account.localAccountId + "." + Math.floor(Math.random() * (999999999999 - 100000000000 + 1)));
        fetchGraphAccessToken();

        // Initialize Pendo
        window.pendo.initialize({
          visitor: {
            id: account.username,
            email: account.username,
            full_name: account.name
          },

          account: {
            id: 'CallGuideBuilder' // Required if using Pendo Feedback
          }
        });
        console.log("Initialized Pendo")
      }
    }, error => {
      //console.log('error', error);
    });

    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in 
      const account = msalInstance.getActiveAccount();
      if (!account || isIDTokenExpired(account)) {
        // redirect anonymous user to login page 
        msalInstance.loginRedirect();
      }
      else {
        setUserName((userName) => account.userName);
        setUser((user) => account.name);
        setUserId((userId) => account.localAccountId + "." + Math.floor(Math.random() * (999999999999 - 100000000000 + 1)));
        fetchGraphAccessToken();
      }
    }).catch(err => {
      // TODO: Handle errors
      //console.log(err);
    });
  }, [])

  const isIDTokenExpired = (activeAccount) => {
    const idTokenExpires = new Date(activeAccount.idTokenClaims.exp * 1000);
    const currentTime = new Date();
    // Calculate the difference in milliseconds between the expiry date and the current time
    const timeDifference = idTokenExpires.getTime() - currentTime.getTime();

    if (timeDifference <= 0) {
      return true;
    } else if (timeDifference <= 5 * 60 * 1000) { // 5 minutes = 5 * 60 * 1000 milliseconds
      return true;
    } else {
      return false;
    }
  }

  const fetchGraphAccessToken = () => {
    msalInstance.acquireTokenSilent({
      scopes: ["user.read"],
      account: accounts[0],
    })
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // console.log(accessTokenResponse.account.name);
        try {
          fetchEmployeeProfilePicture(accessToken);
        } catch (err) {
          console.error(err, "err");
        }
      })
      .catch(function (error) {
        //Acquire token silent failure
        console.log(error);
      });
  }

  const fetchEmployeeProfilePicture = (accessToken) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("Content-type", 'blob');
    const requestOptions = {
      method: "GET",
      headers: headers,
    };
    const url = `https://graph.microsoft.com/v1.0/me/photo/$value`;

    fetch(url, requestOptions)
      .then((response) => {
        if (response != null && response.ok) {
          response.blob().then((data) => {
            if (data !== null) {
              window.URL = window.URL || window.webkitURL;
              // console.log(window.URL.createObjectURL(data));
              setProfileImage((profileImage) => window.URL.createObjectURL(data));
            }
          });
        } else {
          fetchEmployeeProfilePicture(accessToken);
        }
      })
      .catch((error) => {
        fetchEmployeeProfilePicture(accessToken);
      });
  }

  const handleUserGuideClick = () => {
    appInsights.trackEvent("User guide opened", { userId: userId });
    window.open(
      'https://bainandcompany.sharepoint.com/:b:/s/CallGuideRepository/Ef63VD1-MIJCrYUAOfLZC8kBcaMMPjBNzKt8eXhfRRvYyA?e=ZtL6gD',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  const handleCopyClick = () => {
    if (userId !== undefined && userId !== null) {
      navigator.clipboard.writeText(userId.toString().split(".")[1])
    }
  }

  var chatbot;
  const validator = (input) => {
    if (input.length > 0) return true;
    return false
  };

  chatbot = <Chatbot
    config={Config}
    messageParser={MessageParser}
    actionProvider={ActionProvider}
    validator={validator}
  />
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  return (
    <>
      {accounts ? (
        <div className="containerdiv">
          <div className="row rowheader">
            <div className="col topBanner">
              <nav className="navbar navbar-light bg-light">
                <div className="row navRow">
                  <div className='col-md-6 navAppName'>
                    <h4 className="title " href="#">
                      {appName}
                    </h4>
                  </div>
                  <div className='col-md-6 navBainLogoCol'>
                    <img src="https://www.bain.com/contentassets/0b88e3e10a7b4592809517c28b75847e/logo_red_bain.svg" className="d-inline-block align-top" alt="" />
                  </div>
                </div>
                <div className="row navRow">
                  <div className='col navUserGuide'>
                    <button className="button learn-more" onClick={() => handleUserGuideClick()}>
                      <span className="circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                      </span>
                      <span className="button-text">User Guide</span>
                    </button>
                  </div>
                </div>
                <div className="row navRow">
                  {/* <div className='col navUserGuide'>
                    <a href='https://bainandcompany.sharepoint.com/:b:/r/sites/CallGuideRepository/Shared%20Documents/User%20guide.pdf?web=1' target='blank'>User guide</a>
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
          <div className="row rowbody">
            <div className="col">
              <header className="App-header">
                {chatbot}
              </header>
              <div className='surveyDiv'>
                Please submit your feedback on the tool in the following survey:&nbsp;<a href='https://forms.office.com/r/vmjLnw4jNH' target='blank'>link</a>
                &nbsp;<Button className="copybutton"
                  onClick={() => handleCopyClick("Yes")}>
                  Click to copy your session ID
                </Button>
              </div>
              <div className='sessionDiv'>
              </div>
            </div>
            <div className="col">
              <header className="App-header">
                <div className="react-chatbot-kit-chat-container">
                  <PreviewDownload></PreviewDownload>
                </div>
              </header>
            </div>
            <div className='col-md-12 col-sm-12 col-lg-12 disclaimerDiv'>
              <strong>Disclaimer:</strong> The expert call guide generator is built on data from Bain market research and may generate responses that contain sensitive information and / or specific company names. The appearance of any particular company name is not indicative of any particular relationship with Bain & Company. Please be mindful of this potential risk and ensure no confidential information is shared during your interview process.
            </div>
          </div>
          <div className="row">

          </div>
        </div>
      ) : null}
    </>
  );
};

function App(instance) {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;
