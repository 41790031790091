import React, { useContext } from "react";
import { Context } from "../Context";

const InitialMessage = (props) => {
  const { user } = useContext(Context);
  let messageHistory = [];
  const newPrompt = [
    {
      "role": "assistant",
      "content": `Hello ${user? user.split(',')[1]:""}, I am the ${props.botName}. What type of call guide are you building? Customer/Competitor/another type (please describe)?`
    },
  ]

  messageHistory = [...messageHistory, ...newPrompt];

  localStorage.setItem('messageHistory', JSON.stringify(messageHistory));

  return (
    <div>
      Hello<strong>{user ? user.split(',')[1] : ""}</strong>, I am the {props.botName}. I will be asking you a few questions to better understand the call guide you would like to create. Let’s begin. What type of call guide are you building? Customer / Competitor / another type (please describe)?<br></br><br></br><strong>Note:</strong> For this and all subsequent responses, please be mindful of your spelling, as the call guide generator will use your direct verbiage to draft your interview guide
    </div>
  );
};

export default InitialMessage;
