import AcquireAPIToken from "../auth/AcquireAPIToken";

const callDownloadAPIs = async (callguide) => {
  let requestOptions;
  try {

    let acceesToken = await AcquireAPIToken();

    requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + acceesToken },
      body: JSON.stringify({ "callguide": callguide }),
    };

    const response = await fetch(process.env.REACT_APP_API_GENERATE_DOC, requestOptions);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Expertcallguide.docx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    throw error;
  }
}
export default callDownloadAPIs;