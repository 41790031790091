import { toast } from "react-toastify";
import callGuideAPIs from "../apis/api-call-guide";
import callIterateCallGuideAPIs from "../apis/api-iterate-call-guide";
import callPromptAPIs from "../apis/api-prompt";
import callSectionsAPIs from "../apis/api-sections";
import config from "../chatbot/Config";
import ObjectiveMessage from "../components/ObjectiveMessage"
import UserInstructions from "../components/UserInstructions"
import { appInsights } from '../appinsights/appInsights';

class ActionProvider {
  constructor(
    createChatBotMessage,
    setStateFunc,
    createClientMessage,
    stateRef,
    createCustomMessage,
    ...rest
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
    this.createCustomMessage = createCustomMessage;
  }

  botName = process.env.REACT_APP_BOTNAME;
  abcd = <><div><strong>Notes</strong></div></>

  //method for add message in our chatbot
  addMessageToBotState = (messages, widgetSelection) => {
    let messageHistory = JSON.parse(localStorage.getItem("messageHistory")) || [];

    if (widgetSelection != null || widgetSelection !== undefined) {
      const userSelectionMessage = {
        id: Math.floor(Math.random() * (999999999999 - 100000000000 + 1)),
        loading: true,
        message: widgetSelection,
        type: 'user', // or 'bot'
        withAvator: true
      };
      this.setState((state) => ({
        ...state,
        messages: [...state.messages, userSelectionMessage],
      }));

      const newPrompt = [
        {
          "role": "user",
          "content": widgetSelection,
        },
      ]
      messageHistory = [...messageHistory, ...newPrompt];
    }

    if (Array.isArray(messages)) {
      this.setState((state) => ({
        ...state,
        messages: [...state.messages, ...messages],
      }));
    } else {
      this.setState((state) => ({
        ...state,
        messages: [...state.messages, messages],
      }));
    }
    if(typeof(messages.message) !== 'object')
    {
      const newPrompt = [
        {
          "role": "assistant",
          "content": messages.message,
        },
      ]
      messageHistory = [...messageHistory, ...newPrompt];
      localStorage.setItem('messageHistory', JSON.stringify(messageHistory));
    }
  };

  Industry = (prop) => {
    const message = this.createChatBotMessage(
      `What is the industry for the interview? (e.g., Pricing strategy for commercial HVAC)`,
      {
        withAvatar: true,
        content: "Industry",
      }
    );
    this.addMessageToBotState(message);
  };
  FinalizeSections = async (selectedSections) => {
    let paramState = config.state.ParamState;

    // let allSections;
    if (selectedSections !== undefined && selectedSections !== null && selectedSections !== "") {
      let allSelectedSections = selectedSections.map(item => item.value);
      paramState.sections = allSelectedSections.join(", ");
    }
    const newPrompt = [
      {
        "role": "assistant",
        "content": paramState.sections,
      },
    ]

    let messageHistory = JSON.parse(localStorage.getItem("messageHistory")) || [];
    messageHistory = [...messageHistory, ...newPrompt];
     // store the messages array in local storage
    localStorage.setItem('messageHistory', JSON.stringify(messageHistory));

    this.setState((prevState) => ({
      ...prevState,
      paramState: paramState
    }));
    this.Objectives();
  };

  Sections = async () => {
    try{
      let sectionResponse;
      sectionResponse = await callSectionsAPIs(config.state.ParamState.target);

      this.setState((prevState) => ({
        ...prevState,
        Sections: sectionResponse
      }));

      const message = this.createChatBotMessage(
        `Below are typical sections included in a ${config.state.ParamState.target} call guide. Would you like to include any of the below sections?`,
        {
          withAvatar: true,
          content: "Sections",
          widget: "Sections"
        }
      );
      this.addMessageToBotState(message);
    }
    catch(error)
    {
      toast.error("Error: Unable to load sections, please continue without it or refresh the page to start over.",{autoClose:false});
      appInsights.trackException({ error: error, properties: config.state.ParamState.target});
    }
  };
  Objectives = () => {
    const message = this.createChatBotMessage(<ObjectiveMessage></ObjectiveMessage>,
      {
        withAvatar: true,
        content: "Objectives",
        widget: "Objectives"
      }
    );
    this.addMessageToBotState(message);
  };

  ReplaceNextLineCharacters = (text) => {
    return text.replace(/[\r\n]/gm, '');
  }

  ReplaceDoubleNextLineCharacters = (text) => {
    return text.replace(/\n+/g, '');
  }

  ReplaceNextLineCharactersFromStart = (text) => {
    return text.replace(/^\n+/, '');
  }

  FinalObjectives = async () => {
    try{
      let objectiveResponse = "";
      let paramState = config.state.ParamState;

      objectiveResponse = await callPromptAPIs(config.state.ParamState);

      if(objectiveResponse.status === 429 || objectiveResponse.status === 503)
      {
        toast.error("Error: Currently, we are encountering a higher volume of requests than our optimal capacity allows. We kindly request you to revisit after a brief interval and refresh this page for a renewed attempt.",{autoClose:false});
        appInsights.trackException({ error: objectiveResponse, properties: config.state.ParamState });
      }
      else if(objectiveResponse.status)
      {
        toast.error("Error: Unable to generate objectives. Please retry or refresh the page to start over.",{autoClose:false});
        appInsights.trackException({ error: objectiveResponse, properties: config.state.ParamState });
      }
      else
      {
        let mandatory_sections = process.env.REACT_APP_MANDATORY_SECTIONS;
        let allSections = mandatory_sections;
        if(allSections.slice(-1) !==" ")
        {
          allSections  = allSections + " ";
        }
        if (paramState.sections !== undefined && paramState.sections !== null && paramState.sections !== "") {
          // console.log(paramState.sections)

          if (objectiveResponse !== undefined && objectiveResponse !== null && objectiveResponse !== "") {
            allSections = allSections + (paramState.sections ? paramState.sections + ", " : "") + this.ReplaceNextLineCharacters(objectiveResponse);
          }
          else {
            allSections = allSections + paramState.sections;
          }
        }
        else {
          if (objectiveResponse !== undefined && objectiveResponse !== null && objectiveResponse !== "") {
            allSections = allSections + this.ReplaceNextLineCharacters(objectiveResponse);
          }
        }
        if (objectiveResponse) {
          var messageObjective = "Based on your input, here are the suggested sections for the call guide: " + allSections + " \n\nType any adjustments you’d like me to make to the sections list (e.g., add a section on Regulatory changes) or click ‘Generate call guide’ when you are happy with the suggested sections.";
          // var revisionPrompt = [];
          // revisionPrompt = paramState.revision_prompt.length > 0 ? paramState.revision_prompt : [];
          // const prompt_message = [
          //   {
          //     "role": "assistant",
          //     "content": messageObjective
          //   },
          // ]
          // if (revisionPrompt.length === 0)
          //   revisionPrompt = prompt_message;
          // else
          //   revisionPrompt = [...revisionPrompt, ...prompt_message]
          // paramState.revision_prompt = revisionPrompt;
          paramState.objectives = this.ReplaceNextLineCharacters(objectiveResponse);
          this.setState((prevState) => ({
            ...prevState,
            ParamState: paramState,
          }));

          const message = this.createChatBotMessage(messageObjective, {
            withAvatar: true,
            content: "Revision",
            widget: "Revision"
          });
          this.addMessageToBotState(message);
        }
        else {
          // toast.error("Error: Unable to generate objectives. Please retry or refresh the page to start over.",{autoClose:false});
          // appInsights.trackException({ error: objectiveResponse, properties: config.state.ParamState });
        }
      }
    }
  catch(error)
    {
        toast.error("Error: Unable to generate objectives. Please retry or refresh the page to start over.",{autoClose:false});
        appInsights.trackException({ error: error, properties: config.state.ParamState });
    }
  };

  Revision = async () => {
    this.FinalObjectives();
  };

  HandleRevisionsBeforeCallGuideCreation = async (ParamState) => {
    this.FinalObjectives();
  }

  CreateCallGuide = async (ParamState) => {
    let isRevision = false;
    if (config.PreviewState !== undefined && config.PreviewState !== null && config.PreviewState !== "") {
      isRevision = true;
    }
    this.OpenAIResponseHandler(ParamState, isRevision);
  }
  OpenAIResponseHandler = async (ParamState, isRevision) => {
    try{
        // console.log(ParamState);
        let callGuideResponse;

        this.setState((prevState) => ({
          ...prevState,
          Loading: true,
        }));

        if (!isRevision) {
          this.setState((prevState) => ({
            ...prevState,
            PreviewState: ""
          }));

          const message = this.createChatBotMessage("Generating your call guide, please wait...", {
            withAvatar: true,
            content: "Final",
            widget: "Final"
          });
          this.addMessageToBotState(message);
          // console.log(ParamState);
          callGuideResponse = await callGuideAPIs(ParamState);
          if(callGuideResponse.status === 429 || callGuideResponse.status === 503)
          {
            this.setState((prevState) => ({
              ...prevState,
              Loading: false,
            }));

            toast.error("Error: Currently, we are encountering a higher volume of requests than our optimal capacity allows. We kindly request you to revisit after a brief interval and refresh this page for a renewed attempt.",{autoClose:false});
            appInsights.trackException({ error: callGuideResponse, properties: ParamState });
          }
          else if(callGuideResponse.status)
          {
            this.setState((prevState) => ({
              ...prevState,
              Loading: false,
            }));

            toast.error("Error: Unable to generate objectives. Please retry or refresh the page to start over.",{autoClose:false});
            appInsights.trackException({ error: callGuideResponse, properties: ParamState });
          }
          else
          {
            // console.log(callGuideResponse);
            if(callGuideResponse.callguide !== undefined && callGuideResponse.callguide !== null && callGuideResponse.callguide !== "")
            {
              let callGuide = callGuideResponse.callguide;

              this.setState((prevState) => ({
                ...prevState,
                PreviewState: callGuide,
                Loading: false,
              }));
              toast.success("Your draft call guide is ready!",{autoClose:true});
              const finalMessage1 = this.createChatBotMessage("Based on your selections, I’ve drafted an expert call guide draft for you to review.",
                {
                  withAvatar: true,
                  content: "Final",
                  widget: "Final"
                });
              this.addMessageToBotState(finalMessage1);
              const finalMessage2 = this.createChatBotMessage(<UserInstructions></UserInstructions>,
              {
                withAvatar: true,
                content: "Final",
                widget: "Final"
              });
              this.addMessageToBotState(finalMessage2);
              const finalMessage3 = this.createChatBotMessage("Feel free to add any additional details from your case scope that you would like me to address. I am a secure tool based on ChatGPT’s model that can action other types of feedback you might have! Please be patient and as specific as possible with your instructions.",
              {
                withAvatar: true,
                content: "Final",
                widget: "Final"
              });
              this.addMessageToBotState(finalMessage3);
            }
            else
            {
              console.log('callguid')
              console.log(callGuideResponse)
              // console.log(callGuideResponse.statusText)
              this.setState((prevState) => ({
                ...prevState,
                Loading: false,
              }));
              toast.error("Error: Call guide generation failed, please try again or refresh the page to start over.",{autoClose:false});
              appInsights.trackException({ error: "Error: Call guide generation failed, please try again or refresh the page to start over.", properties: {data: ParamState, function: "callGuideAPIs"}});
            }
          }
        }
      else {  
            callGuideResponse = await callIterateCallGuideAPIs(ParamState);
            if(callGuideResponse.status === 429 || callGuideResponse.status === 503)
            {
              this.setState((prevState) => ({
                ...prevState,
                Loading: false,
              }));

              toast.error("Error: Currently, we are encountering a higher volume of requests than our optimal capacity allows. We kindly request you to revisit after a brief interval and refresh this page for a renewed attempt.",{autoClose:false});
              appInsights.trackException({ error: callGuideResponse, properties: ParamState });
            }
            else if(callGuideResponse.status)
            {
              this.setState((prevState) => ({
                ...prevState,
                Loading: false,
              }));

              toast.error("Error: Unable to generate objectives. Please retry or refresh the page to start over.",{autoClose:false});
              appInsights.trackException({ error: callGuideResponse, properties: ParamState });
            }
            else
            {
              if(callGuideResponse.callguide !== undefined && callGuideResponse.callguide !== null && callGuideResponse.callguide !== "")
              {
                let commentary = callGuideResponse.commentary;
                let callGuide = callGuideResponse.callguide;

                this.setState((prevState) => ({
                  ...prevState,
                  PreviewState: callGuide,
                  Loading: false,
                }));
                toast.success("Your draft call guide is ready!",{autoClose:true});
                const finalMessage1 = this.createChatBotMessage(commentary,
                {
                  withAvatar: true,
                  content: "Final",
                  widget: "Final"
                });
                this.addMessageToBotState(finalMessage1);
              }
              else
              {
                console.log('callguiditirate')
                console.log(callGuideResponse)
                this.setState((prevState) => ({
                  ...prevState,
                  Loading: false,
                }));

                toast.error("Error: Call guide generation failed, please try again or refresh the page to start over.",{autoClose:false});
                appInsights.trackException({ error: "Error: call guide generation failed, please try again or refresh the page to start over.", properties: {data: ParamState, function: "callIterateCallGuideAPIs"}});
              }
            }
      }
  }
  catch(error)
  {
    this.setState((prevState) => ({
      ...prevState,
      Loading: false,
    }));

    toast.error("Error: Call guide generation failed, please try again or refresh the page to start over.", {autoClose:false});
    appInsights.trackException({ error: error, properties: {data: ParamState, function: "callIterateCallGuideAPIs"}});   
  }
  };

  saveUserMessage = () => {
    console.log(config.state.messages);
  }
  handleUserMessage = (userMessage) => {
    var lastMessageType = "";
    // var lastMessageContext = "";

    this.setState((state) => {
      lastMessageType = state.messages[state.messages.length - 2] ? state.messages[state.messages.length - 2].content : "";
      // lastMessageContext = state.messages[state.messages.length - 2] ? state.messages[state.messages.length - 2].message : "";
      //console.log(lastMessageType)
      // console.log(lastMessageContext)
      // console.log(userMessage);
      // console.log(state.ParamState);
      // console.log(revisionPrompt);
      const newPrompt = [
        {
          "role": "user",
          "content": userMessage
        },
      ]
      switch (lastMessageType) {
        case "Target":
          state.ParamState.target = userMessage;
          this.Industry();
          break;
        case "Industry":
          state.ParamState.industry = userMessage;
          this.Sections();
          break;
        case "Sections":
          this.Objectives();
          break;
        case "Objectives":
          state.ParamState.userobjectives = (state.ParamState.objectives ? state.ParamState.objectives + ", " : "") + (state.ParamState.userobjectives ? state.ParamState.userobjectives + ", " : "") + userMessage;
          this.FinalObjectives();
          break;
        case "Revision":
          state.ParamState.userobjectives = (state.ParamState.objectives ? state.ParamState.objectives + ", " : "") + userMessage;
          state.ParamState.revision_prompt = newPrompt;
          this.Revision();
          break;
        case "Final":
          state.ParamState.userobjectives = (state.ParamState.objectives ? state.ParamState.objectives + ", " : "") + userMessage;
          state.ParamState.revision_prompt = newPrompt;
          this.CreateCallGuide(state.ParamState);
          break;

        default:
          if(state.PreviewState !== undefined && state.PreviewState !== null && state.PreviewState !== "")
          {
            state.ParamState.userobjectives = (state.ParamState.objectives ? state.ParamState.objectives + ", " : "") + userMessage;
            state.ParamState.revision_prompt = newPrompt;
            this.CreateCallGuide(state.ParamState);
          }
          else
          {
            this.Objectives();
          }
      }
      return state;
    });
  }
}
export default ActionProvider;
