import AcquireAPIToken from "../auth/AcquireAPIToken";
const callSectionsAPIs = async (props) => {
  let requestOptions;
  let url;
  const fetchApi = async (props) => {
    try {

      var acceesToken = await AcquireAPIToken();

      requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + acceesToken },
      };
      if (props === "customer") {
        url = process.env.REACT_APP_API_GET_PREDEFINED_CUSTOMER_SECTIONS;
      }
      else if (props === "competitor") {
        url = process.env.REACT_APP_API_GET_PREDEFINED_COMPETITOR_SECTIONS;
      }
      else {
        url = process.env.REACT_APP_API_GET_PREDEFINED_ALL_SECTIONS;
      }
      return await fetch(url, requestOptions)
        .then((response) => {
            return response.json();
         
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };
  return fetchApi(props);
}
export default callSectionsAPIs;